import React  from "react"
import "./works-list.scss"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const WorksListItem = ({ headerImage, headline, url, locale}) => {
  const localePrefix = locale !== "en" ? `/${locale}` : ""

  return (
    <AniLink cover direction="left" top="entry" duration={.4} bg="#f4f4f0"
             to={`${localePrefix}/works/${url}`}
             className="work-list-item block"
    >
      <div className="image-wrapper">
        <img src={headerImage.url} alt={headerImage.alternativeText} />
        <div className="tags">
          {headline.categories.split(',').map((category, index) => {
            return (
              <span className="tag-item" key={index}>{category.trim()}</span>
            )
          })}
        </div>
      </div>
      <div className="content-wrapper">
        <div className="title">{headline.heading}</div>
        <div className="partner">{headline.partner}</div>
      </div>
    </AniLink>
  )
}

export default WorksListItem

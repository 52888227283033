import React, { useEffect } from "react"
import "./works-list.scss"
import WorksListItem from "./works-list-item"

const WorksList = ({ works, currentTag, allText, othersText, tags }) => {
  const [results, setResults] = React.useState()

  useEffect(() => {
    if (currentTag !== othersText) {
      const term = currentTag === allText ? "" : currentTag
      const items = works.filter(x => x?.headline?.categories?.toLowerCase().includes(term.toLowerCase()))
      setResults(items)
    } else {
      const parsedTags = tags.toLowerCase().split(",")
      const items = works.filter(
        item => {
          const tagsList = item.headline.categories.toLowerCase()
          return !parsedTags.some((el) => tagsList.includes(el))
        })
      setResults(items)
    }
  }, [currentTag, works, allText, othersText, tags])

  return results ? results.map((item, index) => {

    return (
      <div key={index} className="col-12 col-md-6">
        <WorksListItem {...item} />
      </div>
    )
  }) : ""
}

export default WorksList

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import DefaultLayout from "../components/layout/default-layout"
import Footer from "../components/shared/footer/footer"
import BackDropped from "../components/shared/back-dropped/back-dropped"
import HeroSection from "../components/shared/hero-section/hero-section"
import TagsFilter from "../components/works/tags-filter/tags-filter"
import WorksList from "../components/works/works-list/works-list"
import getLocaleEntities from "../helpers/localization-helper"

const Works = ({ locale = "en" }) => {

  const servicePath = "strapiWorksContent"
  const viewData = useStaticQuery(query)
  const localizedData = getLocaleEntities({ viewData, servicePath, locale })


  const servicePathAll = "allStrapiWorks"
  const works = useStaticQuery(query)[servicePathAll + locale.toUpperCase()].nodes

  const theme = localizedData.pageSettings.headerTheme
  const heroSection = localizedData.heroSection
  const tags = localizedData.tags
  const [currentTag, setCurrentTag] = React.useState(tags.allText)

  return <>
    <DefaultLayout type={theme} path="works"
                   heroBackground={heroSection.background}
                   seo={localizedData.pageSettings}
                   wrapperClass="bg-darker">
      <HeroSection {...heroSection} theme={theme}>
        <TagsFilter {...tags} theme={theme} currentTag={currentTag} setCurrentTag={setCurrentTag} works={works} />
      </HeroSection>

      <div className="container py-24">
        <div className="row">
          <WorksList works={works} currentTag={currentTag} {...tags} />
        </div>
      </div>
    </DefaultLayout>
    <Footer locale={locale}/>
    <BackDropped locale={locale} />
  </>
}

export const query = graphql`
    fragment pageDataWorksContent on StrapiWorksContent {
        locale
        heroSection {
            heading
            topText
            background {
                ext
                mime
                url
                alternativeText
            }
        }
        tags {
            allText
            othersText
            tags
        }
        pageSettings {
            title
            headerTheme
        }
    }
    fragment pageDataWorksArticle on StrapiWorks {
        locale
        url
        content1
        content2
        content3
        gallery {
            alternativeText
            url
        }
        headerImage {
            alternativeText
            url
        }
        headerVideo
        headline {
            categories
            heading
            subheading
            partner
            partnerLogo {
                url
                alternativeText
            }
        }
        lead
        pageSettings {
            headerTheme
            title
        }
        tileGallery {
            items {
                title
                videoSource
                tags
            }
        }
    }
    query {
        strapiWorksContent {
            ...pageDataWorksContent
        }

        allStrapiWorksEN: allStrapiWorks(
            sort: {order: DESC, fields: published_at}
            filter: {locale: {eq: "en"}}
        ) {
            nodes {
                ...pageDataWorksArticle
            }
        }
        allStrapiWorksTR: allStrapiWorks(
            sort: {order: DESC, fields: published_at}
            filter: {locale: {eq: "tr"}}
        ) {
            nodes {
                ...pageDataWorksArticle
            }
        }
        allStrapiWorksDE: allStrapiWorks(
            sort: {order: DESC, fields: published_at}
            filter: {locale: {eq: "de"}}
        ) {
            nodes {
                ...pageDataWorksArticle
            }
        }
        allStrapiWorksAR: allStrapiWorks(
            sort: {order: DESC, fields: published_at}
            filter: {locale: {eq: "ar"}}
        ) {
            nodes {
                ...pageDataWorksArticle
            }
        }
    }
`

export default Works

import React from "react"
import "./tags-filter.scss"

const TagsFilter = ({ tags, allText, othersText, theme, currentTag, setCurrentTag, works }) => {
  const allTextCount = works.length
  const parsedTags = tags.toLowerCase().split(",")
  const othersCount = works.filter(item => {
      const tagsList = item.headline.categories.toLowerCase()
      return !parsedTags.some((el) => tagsList.includes(el))
    }
  ).length

 const getCount = (tagToCount) => {
   const items = works.filter(x => x?.headline?.categories?.toLowerCase().includes(tagToCount.toLowerCase()))
   return items.length
 }
  return (
    <div className="row overflow-auto">
      <ul className={"tags-filter p-0 " + theme}>
        <li className={currentTag === allText ? "active" : ""}>
          <button onClick={() => setCurrentTag(allText)}>{allText} <span>{allTextCount}</span></button>
        </li>
        {
          tags?.split(",").map((item, index) => (
            <li key={index} className={currentTag === item ? "active" : ""}>
              <button
                onClick={() => setCurrentTag(item)}
              >
                {item}<span>{getCount(item)}</span>
              </button>
            </li>
          ))
        }

        <li className={currentTag === othersText ? "active" : ""}>
          <button onClick={() => setCurrentTag(othersText)}>{othersText} <span>{othersCount}</span></button>
        </li>
      </ul>
    </div>
  )
}

export default TagsFilter
